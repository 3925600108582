import type { ISbStoriesParams } from 'storyblok-js-client';

// NOTE: Also see useStoryblokStories

export async function useAsyncStoryblokStories(name: string, parameters: ISbStoriesParams) {
    const api = useStoryblokApi();

    const { data } = await useAsyncData(
        name,
        () => api.getStories({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ...parameters,
            version: useRuntimeConfig().public.storyblokVersion === 'published' ? 'published' : 'draft',
        }),
    );

    return data.value?.data.stories || [];
}
